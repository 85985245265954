import React from "react";
import Typography from "@material-ui/core/Typography";

const AboutMe = () => {
  return (
    <div>
      <Typography paragraph>
        Hola! My name is Ana and I'm passionate about teaching languages!
      </Typography>
      <Typography paragraph>This is my website!</Typography>
    </div>
  );
};

export default AboutMe;
